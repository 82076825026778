import React, { useState, useEffect } from "react";
import { fetchEasyLoggerLogs } from "../../api/api";
import { useParams } from "react-router-dom";
import "./EasyLoggerPage.css";
import NavBar from "./NavBar";
import EasyLoggerList from "./EasyLoggerList";
// import { printLogs, printIndexes } from "./LogsUtils.js";
// const uiUtils = require("../../reactUtils/uiUtils");

function EasyLoggerPage() {
  let { id } = useParams();
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    onPageLoad();
  }, [id]);

  async function onPageLoad() {
    document.title = "EasyLoggerPage";
    const logs = await fetchEasyLoggerLogs(id, 3);
    // console.log(`onPageLoad logs ${logs}`);
    // printLogs(logs);
    if (logs != null) {
      setLogs(logs);
    }
  }

  // const idTmp = urlParams.id;
  // return;

  const onRefreshTapped = async (event) => {
    const logs = await fetchEasyLoggerLogs(id, 3);
    if (logs != null) {
      setLogs(logs);
    }
  };
  const onDeleteTapped = async (event) => {
    console.log("too lazy");
  };

  const isNoUser = id == null;
  return (
    <div>
      <NavBar
        id={id}
        onRefreshTapped={onRefreshTapped}
        onDeleteTapped={onDeleteTapped}
        isNouser={isNoUser}
      ></NavBar>
      <EasyLoggerList logs={logs} isNoUser={isNoUser}></EasyLoggerList>
    </div>
  );
}

export default EasyLoggerPage;
