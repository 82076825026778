export function handleChange(
  name,
  value,
  errors,
  setErrors,
  data,
  setData,
  schema
) {
  let errorData = { ...errors };
  const errorMessage = validateProperty(name, value, schema);
  if (errorMessage) {
    errorData[name] = errorMessage;
  } else {
    delete errorData[name];
  }
  let dataTemp = { ...data };
  dataTemp[name] = value;
  setData(dataTemp);
  setErrors(errorData);
}

function validateProperty(name, value, schema) {
  // Create a new schema for only the field that has changed
  // Extract the schema for the specific field
  const fieldSchema = schema.extract(name);

  // Validate only the changed field against its schema
  const { error } = fieldSchema.validate(value);

  // console.log(`validateProperty error ${error} name ${name}`);
  return error ? error.details[0].message : null;
}

export function isValid(data, schema) {
  const result = schema.validate(data);
  const { error } = result;
  if (error) {
    for (let item of error.details) {
      // const name = item.path[0];
      const message = item.message;
      console.log(message);
    }
  }
  return error == null;
}
