import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";

function NavBar({ id, onRefreshTapped, onDeleteTapped }) {
  const userStr = id == null ? "No user" : id;
  return (
    <>
      <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand href="#home">{userStr}</Navbar.Brand>
          <Nav className="me-auto">
            <Button onClick={onRefreshTapped} variant="light">
              Refresh
            </Button>
            <Button onClick={onDeleteTapped} variant="light">
              Delete
            </Button>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
