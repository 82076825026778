import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./DetailImagePage.css";
const uiUtils = require("../../reactUtils/uiUtils");

function DetailImagePage() {
  let urlParams = useParams();

  useEffect(() => {
    onPageLoad();
  });

  async function onPageLoad() {
    document.title = "DetailImagePage";
  }

  var id = urlParams.id;
  var folder = urlParams.folder;
  id = uiUtils.safeString(id);
  folder = uiUtils.safeString(folder);
  const imageUrl = uiUtils.makeGalleryUrl(folder, id);

  // console.log(imageUrl);
  return <img className="fullscreen-image" src={imageUrl} alt="mainImage" />;
}

export default DetailImagePage;
