import React, { useState } from "react";
import "./ProstituteForm.css";
import Joi from "joi";
import { postProstitute } from "../../api/api";
import Input from "../components/input";
import LoadingButton from "@mui/lab/LoadingButton";
import { isValid, handleChange } from "../components/formComponent";
import { useNavigate } from "react-router-dom";
const uiUtils = require("../../reactUtils/uiUtils");
//https://www.geeksforgeeks.org/implementing-joi-module-in-reactjs/

function ProstituteForm(props) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    prostituteName: "",
    description: "",
  });
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Joi schema
  const schema = Joi.object({
    prostituteName: Joi.string()
      .required()
      .label("Prostitute name")
      .min(3)
      .max(80),
    description: Joi.string()
      .label("How did you became prostitute?")
      .empty("")
      .max(1000),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { prostituteName, description } = data;
    setIsLoading(true);

    if (file === undefined) {
      await postProstitute(prostituteName, description, "");
    } else {
      const imageUrl = await uploadFile();
      await postProstitute(prostituteName, description, imageUrl);
    }
    navigate("/prostitutes");
    setIsLoading(true);
  };

  return (
    <div style={{ padding: "50px" }}>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        {renderInput("prostituteName", "Your prostitute name", "text", 30)}
        {<br></br>}
        {renderInput(
          "description",
          "How did you become a prostitute?",
          "text",
          100
        )}
        {<br></br>}
        {renderImageInput()}
        {<br></br>}
        {renderButton("Submit")}
      </form>
    </div>
  );

  function renderInput(name, label, type, height) {
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={(event) => {
          const { name, value } = event.target;
          handleChange(name, value, errors, setErrors, data, setData, schema);
        }}
        error={errors[name]}
        height={height}
      />
    );
  }

  function renderButton(label) {
    return (
      <LoadingButton
        disabled={!isValid(data, schema) || isLoading}
        loading={isLoading}
        onClick={handleSubmit}
        variant="contained"
        color="success"
      >
        {label}
      </LoadingButton>
    );
  }

  function renderImageInput() {
    return (
      <div>
        <p className="font-loader">You can add a sluty photo if you like</p>
        <input
          className="font-loader"
          type="file"
          disabled={isLoading}
          onChange={onFileChange}
        />
      </div>
    );
  }

  async function onFileChange(event) {
    const file = event.target.files[0];
    const resizedImage = await uiUtils.resizeImage(file);
    setFile(resizedImage);
  }

  async function uploadFile() {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sluts_preset");
    data.append("cloud_name", "dyvqqcczt");

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dyvqqcczt/image/upload",
        {
          method: "post",
          body: data,
        }
      );

      const jsonReponse = await response.json();
      return jsonReponse.url;
    } catch (err) {
      return null;
    }
  }
}

export default ProstituteForm;
