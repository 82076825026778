import React, { useState } from "react";
import "./RiddleFormPage.css";
import Joi from "joi";
import { makeRiddle } from "../../api/api";
import Input from "../components/input";
import LoadingButton from "@mui/lab/LoadingButton";
import { isValid, handleChange } from "../components/formComponent";
import { useNavigate } from "react-router-dom";
const uiUtils = require("../../reactUtils/uiUtils");

function RiddleFormPage(props) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    title: "",
    answer: "",
  });
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Joi schema
  const schema = Joi.object({
    title: Joi.string().label("title").empty("").max(1000),
    answer: Joi.string().label("answer").max(200),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { title, answer } = data;
    setIsLoading(true);

    var response = "";
    if (file === undefined) {
      response = await makeRiddle(title, "exact", null, answer);
    } else {
      const imageUrl = await uploadFile();
      response = await makeRiddle(title, "exact", imageUrl, answer);
    }
    console.log("token ", response.token);
    navigate(`/riddle/${response.token}`);
    setIsLoading(true);
  };

  return (
    <div style={{ padding: "50px" }}>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        {renderInput("title", "Type your question here", "text", 100)}
        {<br></br>}
        {renderImageInput()}
        {<br></br>}
        {renderInput(
          "answer",
          "Correct answer/s. Can be comma separated. Example: 'bird, birds, pinguin, flappy bird'",
          "text",
          30
        )}
        {<br></br>}
        {renderButton("Submit")}
      </form>
    </div>
  );

  function renderInput(name, label, type, height) {
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={(event) => {
          const { name, value } = event.target;
          handleChange(name, value, errors, setErrors, data, setData, schema);
        }}
        error={errors[name]}
        height={height}
      />
    );
  }

  function renderButton(label) {
    return (
      <LoadingButton
        disabled={
          !isValid(data, schema) ||
          isLoading ||
          (data.title === "" && file === undefined)
        }
        loading={isLoading}
        onClick={handleSubmit}
        variant="contained"
        color="success"
      >
        {label}
      </LoadingButton>
    );
  }

  function renderImageInput() {
    return (
      <div>
        <p className="font-loader">You can add a photo if you like</p>
        <input
          className="font-loader"
          type="file"
          disabled={isLoading}
          onChange={onFileChange}
        />
      </div>
    );
  }

  async function onFileChange(event) {
    const file = event.target.files[0];
    const resizedImage = await uiUtils.resizeImage(file);
    setFile(resizedImage);
  }

  async function uploadFile() {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "riddle_preset");
    data.append("cloud_name", "dyvqqcczt");

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dyvqqcczt/image/upload",
        {
          method: "post",
          body: data,
        }
      );

      const jsonReponse = await response.json();
      return jsonReponse.url;
    } catch (err) {
      return null;
    }
  }
}

export default RiddleFormPage;
