import { getProstitutes } from "../../api/api";
import React, { useState, useEffect } from "react";
import parseHtml from "html-react-parser";
//https://github.com/remarkablemark/html-react-parser
import {
  CCard,
  CCardImage,
  CCardTitle,
  CCardText,
  CCardBody,
  CNavbar,
  CContainer,
  CButton,
} from "@coreui/bootstrap-react";
import "./ProstitutesPage.css";
import { NavLink } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
//https://coreui.io/bootstrap-react/components/card/

function ProstitutesPage() {
  const [prostitutesArr, setProstitutesArr] = useState(null);
  let [page, setPage] = useState(1);
  let [totalPages, setTotalPages] = useState(1);
  const cardsPerPage = 10;

  useEffect(() => {
    onPageLoad();
  }, [page]);

  async function onPageLoad() {
    document.title = "Prostitute database";
    const skip = (page - 1) * cardsPerPage;
    const prostitutesResult = await getProstitutes(skip, cardsPerPage);
    if (prostitutesResult === undefined) {
      setProstitutesArr([]);
      return;
    }

    setProstitutesArr(prostitutesResult.data);
    const totalPages = Math.ceil(
      prostitutesResult.totalElements / cardsPerPage
    );
    setTotalPages(totalPages);
  }

  if (prostitutesArr == null) {
    //loading
    return;
  }
  return screenView();

  function screenView() {
    return (
      <div className="centered-div">
        {headerView()}
        {prostitutesArr.length === 0 ? EmptyArrayView() : makeListView()}
      </div>
    );
  }

  function makeListView() {
    return (
      <div>
        {prostitutesArr.map((prostitute, index) => (
          <div key={prostitute.id}>
            {Card(prostitute)}
            <br></br>
            <br></br>
          </div>
        ))}
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            showLastButton
          />
        )}
        <br></br>
      </div>
    );
  }

  function handlePageChange(event, page) {
    setPage(page);
  }

  function Card(prostitute) {
    // console.log(prostitute.description);
    return (
      <CCard style={{ width: "25rem" }}>
        {prostitute.url ? (
          <CCardImage orientation="top" src={prostitute.url} />
        ) : null}
        <CCardBody>
          <CCardTitle>{prostitute.title}</CCardTitle>
          {renderDescription(prostitute.description)}
        </CCardBody>
      </CCard>
    );
  }

  function renderDescription(description) {
    return description ? (
      <CCardText className="font-loader">
        {
          //suport url a href tag in text
          //And all other tags cause I don't know how to filter the them
          //We sanitizeHtml on BE to prevent html-react-parser crashing
          parseHtml(description)
        }
      </CCardText>
    ) : (
      ""
    );
  }

  function EmptyArrayView() {
    return (
      <CCard style={{ backgroundColor: "#FEC09E" }}>
        <CCardBody>
          <CCardTitle className="font-loader">
            {
              "It is lonely here. No prostitutes found. Loneliness will not go away, when they come."
            }
          </CCardTitle>
        </CCardBody>
      </CCard>
    );
  }

  function headerView() {
    return (
      <CNavbar
        colorScheme="light"
        className="bg-light bg-opacity-50"
        placement="sticky-top"
      >
        <CContainer>
          <div className="centered-div">
            <h3 className="font-loader">
              This is it, finally. The prostitute database.
            </h3>
            <NavLink className="nav-link" to="/prostituteForm">
              <CButton className="font-loader" color="light">
                Add your prostitute card
              </CButton>
            </NavLink>
          </div>
        </CContainer>
      </CNavbar>
    );
  }
}

export default ProstitutesPage;
