import Resizer from "react-image-file-resizer";

export async function resizeImage(image) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      image,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
}

export function safeString(str) {
  var safeStr = str.replace("%20", " ");
  //remove special characters
  //allow single dot
  safeStr = safeStr.replace(
    /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]+(\.(?!\.))/gi,
    ""
  );

  return safeStr;
}

export function makeGalleryUrl(folder, id) {
  const serverPort = 4000;
  var currentDomain = window.location.host;
  // Replace the port number in the current domain string
  currentDomain = currentDomain.replace(/:\d+$/, `:${serverPort}`);
  const imageUrl = `http://${currentDomain}/g/${folder}/${id}`;
  return imageUrl;
}
