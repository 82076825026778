import { getRiddle, answerRiddle } from "../../api/api";
import React, { useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useParams } from "react-router-dom";
// import Input from "../components/input";

import {
  CCard,
  CCardImage,
  CCardTitle,
  CCardBody,
  CNavbar,
  CContainer,
  CButton,
} from "@coreui/bootstrap-react";
import "./RiddlePage.css";
import { NavLink } from "react-router-dom";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";

function RiddlePage() {
  const [riddle, setRiddle] = useState(null);
  const [isError, setIsError] = useState(false);
  const [answerFromUser, setAnswerFromUser] = useState("");
  const [resultMessage, setResultMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  let urlParams = useParams();

  useEffect(() => {
    onPageLoad();
  });

  async function onPageLoad() {
    const token = urlParams.token;

    document.title = "Riddle";
    const riddleTemp = await getRiddle(token);

    if (isError) {
      return;
    } else if (riddle == null) {
      if (riddleTemp != null) {
        setRiddle(riddleTemp);
        setIsError(false);
      } else {
        setIsError(true);
      }
    }
  }

  //   console.log("riddle pp ", riddle);
  if (isError) {
    return ErrorView("No such token");
  } else if (riddle == null) {
    //loading
    return "";
  }

  return screenView();

  function screenView() {
    return (
      <div className="centered-div">
        {Card(riddle)}
        {InputFieldCard(riddle)}
        {resultCard()}
        {footerView(riddle)}
      </div>
    );
  }

  function Card(riddle) {
    return (
      <CCard style={{ width: "25rem", padding: "1rem", marginTop: "50px" }}>
        <CCardBody>
          <CCardTitle>{riddle.title}</CCardTitle>
        </CCardBody>
        {riddle.title ? (
          <CCardImage orientation="top" src={riddle.url} />
        ) : null}
      </CCard>
    );
  }

  function InputFieldCard(riddle) {
    if (isAnswered) {
      return;
    }
    return (
      <Box
        style={{ marginTop: "25px" }}
        component="form"
        sx={{
          "& > :not(style)": { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          size="small"
          label="Answer here"
          value={answerFromUser}
          onChange={(event) => {
            const { value } = event.target;
            setAnswerFromUser(value);
          }}
        />
        {renderButton("Submit")}
      </Box>
    );
  }

  function renderButton(label) {
    return (
      <LoadingButton
        disabled={isLoading || !answerFromUser}
        loading={isLoading}
        onClick={handleSubmit}
        variant="outlined"
        color="success"
      >
        {label}
      </LoadingButton>
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    const response = await answerRiddle(riddle.token, answerFromUser);
    if (response.isRight) {
      setIsAnswered(true);
      setResultMessage(`Correct! The answer is '${response.answer}'`);
    } else {
      setResultMessage(`No, it's not '${answerFromUser}'`);
    }
    setIsLoading(false);
  }

  function resultCard() {
    if (resultMessage) {
      return (
        <div
          style={{ marginTop: "30px", color: "green" }}
          className="centered-div"
        >
          <h2 className="font-loader">{resultMessage}</h2>
        </div>
      );
    }
  }
  function footerView(riddle) {
    return (
      <CNavbar
        style={{ margin: "50px" }}
        colorScheme="light"
        className="bg-light bg-opacity-50"
      >
        <CContainer>
          <div className="centered-div">
            {riddle.count === 0 ? (
              ""
            ) : (
              <p className="font-loader">
                There where {riddle.count} correct answers
              </p>
            )}
            <NavLink className="nav-link" to="/riddle/create">
              <CButton className="font-loader" color="success">
                Create another riddle
              </CButton>
            </NavLink>
          </div>
        </CContainer>
      </CNavbar>
    );
  }
  function ErrorView(message) {
    return (
      <CCard style={{ backgroundColor: "#FEC09E" }}>
        <CCardBody>
          <CCardTitle className="font-loader">{message}</CCardTitle>
        </CCardBody>
      </CCard>
    );
  }
}

export default RiddlePage;
