import React, { useState, useEffect } from 'react'
import {
    GoogleMap,
    useLoadScript,
    Marker,
    Polyline,
} from '@react-google-maps/api'
import { fetchEasyLoggerLogs } from '../../api/api'
import {
    calculateSegmentColor,
    calculateDistance,
    extractLatLng,
} from './LogsUtils.js'
import { useParams } from 'react-router-dom'

const libraries = ['places']
const mapContainerStyle = {
    width: '100vw',
    height: '100vh',
}

function MapPage() {
    let { days } = useParams()
    const [coordinates, setCoordinates] = useState([])

    useEffect(() => {
        onPageLoad()
    }, [])

    async function onPageLoad() {
        document.title = 'Logger Map'
        const logs = await fetchEasyLoggerLogs('shortcuts', days)

        // const logs = [
        //     {
        //         message:
        //             "Pney Hagiv'ah Street 12, Pney Hagiv'ah Street, Ramat Gan, Tel Aviv District, Israel. 32.0877195447301, 34.80973574833482",
        //     },
        //     {
        //         message:
        //             "Pney Hagiv'ah Street 12, Pney Hagiv'ah Street, Ramat Gan, Tel Aviv District, Israel. 32.0887195447301, 34.80973574833482",
        //     },
        //     {
        //         message:
        //             "Pney Hagiv'ah Street 12, Pney Hagiv'ah Street, Ramat Gan, Tel Aviv District, Israel. 32.0897195447301, 34.80973574833482",
        //     },
        //     {
        //         message:
        //             "Pney Hagiv'ah Street 12, Pney Hagiv'ah Street, Ramat Gan, Tel Aviv District, Israel.   32.0907195447301, 34.80973574833482",
        //     },
        //     {
        //         message:
        //             "Pney Hagiv'ah Street 12, Pney Hagiv'ah Street, Ramat Gan, Tel Aviv District, Israel.   32.0917195447301, 34.80973574833482",
        //     },
        // ]

        if (logs != null) {
            const coords = logs.map((item) => extractLatLng(item.message))
            setCoordinates(coords)
        }
    }

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    })

    if (loadError) {
        return <div>Error loading maps</div>
    }

    if (!isLoaded) {
        return <div>Loading map</div>
    }

    const lastLocation = coordinates?.[0]
    // console.log(`lastLocation lat ${lastLocation.lat} lng ${lastLocation.lng} `)
    return (
        <div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={13}
                center={lastLocation}
                onClick={handleMapClick}
            >
                {renderLines(coordinates)}
                {renderMarkers(coordinates)}
            </GoogleMap>
        </div>
    )

    function renderLines(coordinates) {
        if (coordinates.length == 0) {
            return
        }
        return coordinates.map((coord, index) => {
            if (index === 0) return null // Skip the first point

            const path = [coordinates[index - 1], coord]
            return (
                <Polyline
                    key={index}
                    path={path}
                    options={{
                        strokeColor: calculateSegmentColor(
                            index - 1,
                            coordinates.length - 1
                        ),
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                    }}
                />
            )
        })
    }

    function renderMarkers(coordinates) {
        let totalDistance = 0
        let previousCoord = coordinates[0]
        const total = coordinates.length

        return coordinates.map((coord, index) => {
            if (index > 0) {
                const distance = calculateDistance(previousCoord, coord)
                totalDistance += distance
                previousCoord = coord
            }

            // Format distance based on its value
            let displayDistance = null
            if (index > 0 && index <= 15) {
                if (totalDistance < 1000) {
                    displayDistance = `${Math.round(totalDistance)} m`
                } else {
                    displayDistance = `${(totalDistance / 1000).toFixed(1)} km`
                }
            }
            // console.log(`displayDistance ${displayDistance} index ${index} totalDistance ${totalDistance}`);

            return (
                <Marker
                    key={index}
                    position={coord}
                    icon={createCustomMarker(index + 1, displayDistance)}
                    zIndex={total - index}
                />
            )
        })
    }

    // Function to create a custom SVG marker
    function createCustomMarker(number, distance) {
        const radius = 8
        const baseWidth = 50 // Base width of the SVG canvas
        const baseHeight = 50 // Height of the SVG canvas
        const fontSize = 12 // Font size for distance text

        // Calculate necessary width based on the length of the distance string
        let extraWidth = 0
        if (distance) {
            // Approximate width needed per character in pixels, this value may need adjustment
            const widthPerChar = 7
            const textWidth = distance.length * widthPerChar
            extraWidth = Math.max(0, textWidth + 10 - baseWidth) // Ensure there's at least 10px padding if needed
        }

        const svgWidth = baseWidth + extraWidth

        // Centering the text elements properly within the SVG
        const centerX = svgWidth / 2
        let textElement = `<text x="${centerX}" y="25" font-size="14" fill="#000" font-weight="bold" text-anchor="middle">${number}</text>`
        if (distance !== null) {
            textElement += `<text x="${centerX}" y="40" font-size="${fontSize}" fill="#0000ff" font-weight="bold" text-anchor="middle">${distance}</text>`
        }

        const svgMarker = `<svg width="${svgWidth}" height="${baseHeight}" xmlns="http://www.w3.org/2000/svg">
    <circle cx="${centerX}" cy="20" r="${radius}" fill="#FFFFFF" stroke="#000000" stroke-width="2"/>
    ${textElement}
  </svg>`

        return {
            url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svgMarker),
            scaledSize: new window.google.maps.Size(svgWidth, baseHeight),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(centerX, 20), // Centering the anchor point
        }
    }

    function handleMapClick(e) {
        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&travelmode=walking`
        // Open the URL to start navigation in Google Maps
        window.open(googleMapsUrl, '_blank')
    }
}

export default MapPage
