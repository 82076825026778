import React, { useEffect } from "react";

function GalleryFoldersPage() {
  useEffect(() => {
    onPageLoad();
  });

  async function onPageLoad() {
    document.title = "GalleryFoldersPage";
  }

  return (
    <ul>
      {makeElement("colambia", "/gallery/set/colambia")}
      {makeElement("guash", "/gallery/set/guash")}
      {makeElement("soviet", "/gallery/set/soviet")}
    </ul>
  );
}

function makeElement(title, url) {
  return (
    <li>
      <a href={url}>
        <p style={{ fontWeight: "bold", color: "green" }}>{title}</p>
      </a>
    </li>
  );
}

export default GalleryFoldersPage;
