import "./App.css";
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import InvalidPage from "./pages/components/InvalidPage";
import ProstitutesPage from "./pages/prostitutes/ProstitutesPage";
import ProstituteForm from "./pages/prostitutes/ProstituteForm";
import RiddlePage from "./pages/riddle/RiddlePage";
import RiddleFormPage from "./pages/riddle/RiddleFormPage";
import DetailImagePage from "./pages/gallery/DetailImagePage";
import GalleryFoldersPage from "./pages/gallery/GalleryFoldersPage";
import GallerySetPage from "./pages/gallery/GallerySetPage";
import EasyLoggerPage from "./pages/easyLogger/EasyLoggerPage";
import MapPage from "./pages/easyLogger/MapPage";

class App extends Component {
  render() {
    return (
      <Routes>
        <Route exact path="/prostitutes" element={<ProstitutesPage />} />
        <Route exact path="/prostituteForm" element={<ProstituteForm />} />
        <Route exact path="/riddle" element={<RiddleFormPage />} />
        <Route exact path="/riddle/:token" element={<RiddlePage />} />
        <Route exact path="/riddle/create" element={<RiddleFormPage />} />
        <Route exact path="/logger" element={<EasyLoggerPage />} />
        <Route exact path="/logger/map/:days" element={<MapPage />} />
        <Route exact path="/logger/:id" element={<EasyLoggerPage />} />

        <Route
          exact
          path="/gallery/:folder/:id"
          element={<DetailImagePage />}
        />
        <Route exact path="/gallery/set/:id" element={<GallerySetPage />} />
        <Route exact path="/gallery" element={<GalleryFoldersPage />} />

        <Route exact path="/" element={<InvalidPage />} />
      </Routes>
    );
  }
}

export default App;
