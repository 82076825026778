//Prostitutes
export async function getProstitutes(skip, take) {
  // console.log("GET 1 ");
  return await fetch(`api/prostitutes/${skip}/${take}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((response) => {
      // If request is not successful, display error message
      // if (!response.ok) {
      //   throw new Error("HTTP status " + response.status);
      // }
      // console.log(response);
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function postProstitute(prostituteName, description, url) {
  // console.log("client postProstitute");
  return await fetch("api/prostitute", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      prostituteName: prostituteName,
      description: description,
      url: url,
    }),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}

//Riddle
//=================================

export async function getRiddle(token) {
  const domain = window.location.origin; //localhost:8080

  return await fetch(`${domain}/api/riddle/${token}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("getRiddle response err ", err);
      console.log(err);
    });
}

export async function makeRiddle(title, condition, url, answer) {
  const domain = window.location.origin;

  return await fetch(`${domain}/api/riddle`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      title: title,
      condition: condition,
      url: url,
      answer: answer,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function answerRiddle(token, answer) {
  const domain = window.location.origin;

  return await fetch(`${domain}/api/riddle/answer`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token: token,
      answer: answer,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}

//Gallery
//=================================

export async function getGallerySet(id) {
  const domain = window.location.origin;

  // console.log("GET getGallerySet " + id);
  return await fetch(`${domain}/api/gallery/set/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((response) => {
      // console.log("GET getGallerySet ok");
      // console.log(response);
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}

//Easy Logger
//=================================
export async function fetchEasyLoggerLogs(id, days) {
  const domain = window.location.origin;

  console.log(`GET fetchEasyLoggerLogs id ${id} days ${days} domain ${domain}`);
  const idTemp = id == null ? " " : id;
  return await fetch(`${domain}/api/easyLogger/${idTemp}/${days}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((response) => {
      // console.log("GET fetchEasyLoggerLogs ok");
      // console.log(response);
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}
