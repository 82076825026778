import React from "react";
// import { genresAll } from "../../services/fakeGenresService";

const Input = ({ type, name, value, label, onChange, error, height }) => {
  // console.log(`INPUT error: ${error}, name: ${name}, label: ${label}`);
  return (
    <div className="form-group">
      <label className="font-loader" htmlFor={name}>{label}</label>
      <textarea
      style={{ height: height}}
        id={name}
        type={type}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        error={error}
        className="form-control"
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Input;
